var $ = jQuery.noConflict();

$(document).ready(function ($) {
    $(document).checkSize();
    $(document).hideSidebar();
    $(document).tabCookie();
    $(document).apartmentDefault();
    $(document).morph();
    $(document).checkboxSelect();
    $(document).task();
    $(document).sum();
    // $(document).taskCharge();
    $(document).tasksInliner();
    $(document).tasksInlinerSupplier();
    $(document).checkAll();
    $(document).invoiceViewer();
    $(document).expenseViewer();
    $(document).supplierViewer();
    $(document).confirmDelete();
    $(document).formSubmit();
    $(document).taxCompiler();
    $(document).taxCalculator();
    $(document).yearSelect();
    $(document).menuLink();
    $(document).importHoused();
    $(document).newCount();

    if ($('form')) {
        $('form').dirtyManager();
    }

    if ($('[name="safe"]').val() == 1) {
        $('form').lockForm();
    } else {
        $guardian.save = true;
        $(document).resetSection();
        $(document).apartmentUpdate();
        $(document).nightsUpdate();
        $(document).moneyUpdate();
        $(document).switch();
        $(document).providerSelect();
    }

    $(document).on('click', '#provider_link', function () {
        if ($('#provider_conversation_link').val()) {
            window.open($('#provider_conversation_link').val());
        }
    });

    $(document).on('click', '#token_ita_link', function () {
        if ($('#accesstoken_ita_link').val()) {
            window.open($('#accesstoken_ita_link').val());
        }
    });

    $(document).on('click', '#token_eng_link', function () {
        if ($('#accesstoken_eng_link').val()) {
            window.open($('#accesstoken_eng_link').val());
        }
    });

    $(document).on('click', '.uk-active > a', function () {
        var search_button = $('.search-expenses');
        if (search_button.length === 1) {
            search_button.click();
        }
    });

    $(window).on('resize', function () {
        $(document).checkSize();
    });
});

(function ($, window, document, undefined) {

    $window = $(window);
    $document = $(document);
    $body = $('body');
    $html = $('html');
    $guardian = {};
    g = f = void 0;

    $.fn.decodeUIdata = function (str) {
        return JSON.parse(str.replace(/([\$\w]+)\s*:/g, function (_, $1) {
            return '"' + $1 + '":';
        }).replace(/'([^']+)'/g, function (_, $1) {
            return '"' + $1 + '"';
        }));
    };

    $.fn.tabCookie = function () {
        if ($.cookie('tab') !== undefined) {
            $foo = JSON.parse($.cookie('tab'));
            if ($foo && $foo.p == window.location.pathname) {
                $('.uk-tab li').removeClass('uk-active').attr('aria-expanded', false);
                $($foo.t + ' li').removeClass('uk-active').attr('aria-expanded', false).attr('aria-hidden', true);
                $('.uk-tab li').eq($foo.i).addClass('uk-active').attr('aria-expanded', true);
                $($foo.t + ' li').eq($foo.i).addClass('uk-active').attr('aria-expanded', true).attr('aria-hidden', false);
            } else {
                $.cookie('tab', null);
            }
        }
        $('.uk-tab li').on('click', function (e) {
            e.preventDefault();
            $this = $(this);
            $parent = $this.parent("ul");
            $parent.data = $document.decodeUIdata($parent.data('uk-switcher'));
            $.cookie('tab', JSON.stringify({i: $this.index(), t: $parent.data.connect, p: window.location.pathname}));
        });
        return this;
    };

    $.fn.checkSize = function () {
        $window.on('load resize', function (e) {
            e.preventDefault();
            if ($('#sidebar').css('left') == "-250px") {
                if (!$body.hasClass('sidebar-collapsed')) {
                    $body.addClass('sidebar-collapsed');
                } else if ($body.hasClass('sidebar-show')) {
                    $body.removeClass('sidebar-show');
                }
            } else {
                if ($body.hasClass('sidebar-collapsed')) {
                    $body.removeClass('sidebar-collapsed');
                } else {
                    $body.addClass('sidebar-show');
                }
            }
        });
    }

    $.fn.hideSidebar = function () {
        $document.on('click', '[class*="js-menutoggle"]', function () {
            $body.toggleClass('sidebar-collapsed').toggleClass('sidebar-show');
        });
        return this;
    }

    $.fn.checkAll = function () {
        $('[class*="js-checkall"]').change(function () {
            $('[class*="js-checkme"]').prop('checked', $(this).prop("checked")).change();
        });
        return this;
    };

    $.fn.dirtyManager = function () {
        $dirty = {};
        var $form = ($(this).is('form')) ? $(this) : $(this).parents('form');
        _scan();
        $fields.each(function () {
            _storeOriginal($(this));
        });
        $fields.unbind('change input', $fields);
        $fields.bind('change input', $fields, function (e) {
            _checkDirty($(e.target)) ? _addDirty() : false;
            _scan();
        });

        function _getValue(item) {
            var val,
                type = item.is('select') ? 'select' : item.attr('type');
            switch (type) {
                case 'checkbox':
                case 'radio':
                    val = item.is(':checked');
                    break;
                case 'select':
                    val = '';
                    item.find('option').each(function (o) {
                        var $opt = $(this);
                        if ($opt.is(':selected')) {
                            val += $opt.val();
                        }
                    });
                    break;
                default:
                    val = item.val();
                    break;
            }
            return val;
        }

        function _storeOriginal(item) {
            item.attr('data-original') ? item.data('original', _getValue(item)) : item.attr('data-original', _getValue(item));
        }

        function _checkDirty(item) {
            return true === (undefined === item.data('original') ? false : _getValue(item) != item.data('original')) ? _addDirty() : false;
        }

        function _addDirty() {
            $html.addClass('is-dirty');
            $('[class*="js-dirty"]').each(function () {
                $(this).addClass('button-locked');
            });
        }

        function _scan() {
            $fields = $form.find(":input:not(input[type=submit]):not(input[type=button]):not([name='_token']):not([name='_method']):not([name*='autocomplete'])");
        }
    };

    $.fn.guardian = function (name, bool) {
        $guardian[name] = bool;
        return this;
    };

    $.fn.numberFormat = function (value) {
        return value !== 'ERRORE' ? isNaN(parseFloat(value)) ? 0.00 : parseFloat(value) : 'ERRORE';
    };

    $.fn.numberRound = function (value) {
        return Math.round(value * 100) / 100;
    };

    $.fn.morph = function () {
        $document.on('change', '[class*="js-morph"]', function () {
            $('[class*="js-morph"]').each(function () {
                _morph($(this));
            });

            function _morph(e) {
                val = e.val();
                if (val > 0) {
                    newval = parseFloat(val).toFixed(2);
                    e.val(newval);
                }
            }
        });
        return this;
    };

    $.fn.tasksInliner = function () {
        $document.on('change update', '[class*="js-inliner"]', function (e) {
            arr = {}, error = id = false;
            e.preventDefault();
            _evaluate($(this));
            if (error === false) _calculate();
            _updateDB();
            $row.find('[class*="js-spin"]').fadeOut();
            $row.find('[class*="js-icon"]').show();
        });

        function _evaluate(item) {
            $row = item.closest('tr');
            id = $row.attr('id');
            arr['id'] = (id.split('-'))[1];
            $row.find('[class*="js-icon"]').hide();
            $row.find('[class*="js-spin"]').fadeIn();
            $row.find("input:not([name='taxable_amount']), select").each(function () {
                if ($(this).attr('name') == 'dt') {
                    arr['dt'] = $(this).val();
                } else if ($(this).val() == '' || $(this).val() == 0) {
                    error = true;
                } else {
                    arr[$(this).attr('name')] = $document.numberFormat($(this).val());
                }
            });
        }

        function _calculate() {
            var taxable, tax, total;
            taxable = arr['rate_per_hour'] * arr['duration'];
            arr['taxable_amount'] = $document.numberFormat(parseFloat(taxable).toFixed(2));
            $row.find('input[name="taxable_amount"]').val(parseFloat(taxable).toFixed(2));
        }

        function _updateDB() {
            token = $('meta[name="csrf-token"]').attr('content');
            $.ajax({
                beforeSend: function (xhr) {
                    return xhr.setRequestHeader('X-XSRF-Token', token);
                },
                url: $row.data('storage'),
                data: {arr: arr},
                type: 'PATCH',
                dataType: 'json'
            });
        }

        return this;
    };

    $.fn.tasksInlinerSupplier = function () {
        $('[class*="js-inlinesupplier"]').on('change update', function (e) {
            $this = $(this),
                $row = $this.closest('tr');
            if ($this.data('supplier')) {
                token = $('meta[name="csrf-token"]').attr('content');
                $.ajax({
                    beforeSend: function (xhr) {
                        return xhr.setRequestHeader('X-XSRF-Token', token);
                    },
                    url: $this.data('supplier'),
                    data: 'q=' + $this.val(),
                    type: 'GET',
                    dataType: 'json'
                }).done(function (e) {
                    $row.find('input[name="rate_per_hour"]').val(e.fixed_rate_per_hour).change();
                    $row.find('a[class*="js-supplier-viewer"]').show();
                });
            }
        });
    }

    $.fn.task = function () {
        $document.on('change', '[class^="js-supplier"]', function () {
            $this = $(this); //comment this
            var name = ($this.attr('name').split('__'))[0],
                token = $('meta[name="csrf-token"]').attr('content');
            if ($this.val()) {
                $.ajax({
                    beforeSend: function (xhr) {
                        return xhr.setRequestHeader('X-XSRF-Token', token);
                    },
                    url: $this.data('supplier'),
                    data: 'q=' + $this.val(),
                    type: 'GET',
                    dataType: 'json'
                }).done(function (e) {
                    $('[name="' + name + '__rate_per_hour"]').val(e.fixed_rate_per_hour).change();
                    //change also in Reservations
                    $('[name="' + name + '__taxable_amount"]').val(e.fixed_rate_per_hour).change();
                    duration = ($('[name="' + name + '__duration"]').val() > 0) ? ($('[name="' + name + '__duration"]').val()) : 1;
                });
            }
        });
        $document.on('change', '[class^="js-task"]', function () {
            $this = $(this);
            var name = ($this.attr('name').split('__'))[0],
                rate = $('[name="' + name + '__rate_per_hour"]').val(),
                duration = $('[name="' + name + '__duration"]').val();
            $('[name="' + name + '__taxable_amount"]').val(rate * duration).change();
        });
        return this;
    };

    $.fn.sum = function () {
        $document.on('change', '[class*="js-sum"]', function () {
            var value = 0.00,
                checked = $('[class*="js-sum"]:checked').length;
            var touristtax_value = 0.00;
            if (checked) {
                $('[class*="js-sum"]').each(function () {
                    if ($(this).is(':checked')) {
                        value = value + $document.numberFormat($(this).data('sum'));
                        touristtax_value += $document.numberFormat($(this).data('tds-sum'));
                    }
                });
            }
            $('[class*="js-sumresult"]').val(value.toFixed(2));
            //set tourist taxes
            $('[class*="js-tds-sumresult"]').val(touristtax_value.toFixed(2));

        });
        return this;
    }

    $.fn.apartmentUpdate = function () {
        $document.on('change', '[class^="js-apartment"]', function () {
            $this = $(this);
            var select = $this.val(),
                token = $('meta[name="csrf-token"]').attr('content');
            $.ajax({
                beforeSend: function (xhr) {
                    return xhr.setRequestHeader('X-XSRF-Token', token);
                },
                url: $this.data('apartment'),
                data: 'q=' + select,
                type: 'GET',
                dataType: 'json'
            }).done(function (e) {
                $('[name="fixed_fee"]').val(e.fixed_fee).change();
                $('[name="fixed_fee_per_guest"]').val(e.fixed_fee_per_guest).change();
                $('[name="fixed_min_guest"]').val(e.fixed_min_guest).change();
                $('[name="fixed_min_nights"]').val(e.fixed_min_nights).change();
                $('[name="fixed_rate_guest"]').val(e.fixed_rate_guest).change();
                $('[name="fixed_rate_host"]').val(e.fixed_rate_host).change();
                $('[name="fixed_utilities"]').val(e.fixed_utilities).change();
                $('[name="max_guests"]').val(e.max_guests).change();
                $('[name="fixed_cleaning_base"]').val(e.fixed_cleaning_base).change();
                $('[name="fixed_cleaning_whites"]').val(e.fixed_cleaning_whites).change();
                $('[name="fixed_cleaning_whites_more"]').val(e.fixed_cleaning_whites_more).change();
                $('[name="fixed_sim1"]').val(e.fixed_sim1).change();
                $('[name="fixed_sim2"]').val(e.fixed_sim2).change();
                $('[name="fixed_sim3"]').val(e.fixed_sim3).change();
                $('[name="fixed_step7"]').val(e.fixed_step7).change();
                $('[name="fixed_step14"]').val(e.fixed_step14).change();
                $('[name="ooc"]').prop('checked', e.ooc == 1 ? true : false).change();

                $('.js-date-datein').trigger('change');

                var $pax = $('[name="pax"]');
                var empty_pax = $pax.val();
                $('option[value!=""]', $pax).remove();
                for (var i = 1; i <= e.max_guests; i++) {
                    $pax.append($('<option>').attr('value', i).text(i + ' Guest '));
                }
                if (empty_pax > e.max_guests) {
                    $('option[value=""]', $pax).attr('selected', 'selected').change();
                }

            });
        });
        return this;
    };

    $.fn.nightsUpdate = function () {

        var $in = $('.js-date-datein'),
            $out = $('.js-date-dateout'),
            $tot = $('.js-date-counter');

        $document.on('change', '[class*="js-date-"]', function (e) {
            e.preventDefault();
            var i = _parseDate($in.val()),
                o = _parseDate($out.val()),
                n = $('[name="fixed_min_nights"]').val() ? $('[name="fixed_min_nights"]').val() : 'ERRORE',
                r = Math.round((o - i) / (1000 * 60 * 60 * 24));
            if (i > o) {
                // UIkit.notify('Combinazione check-in e check-out non valida'),
                //     $in.addClass('validation-error'), $tot.val('ERRORE');
                $document.guardian('error', true);
                //set checkout date same to checkin + min number of nights (2 days default)

                var nn = $('[name="fixed_min_nights"]').val() ? parseInt($('[name="fixed_min_nights"]').val()) : 0;
                if (nn == 0) {
                    nn = 2;//default number of nights
                }
                o.setTime(i.getTime() + nn * 1000 * 60 * 60 * 24);
                var checkoutStr = ("0" + o.getDate()).slice(-2) + "-" + ("0" + (o.getMonth() + 1)).slice(-2) + "-" +
                    o.getFullYear();

                $out.val(checkoutStr);
            } else if (r < n || i == o) {
                UIkit.notify('Numero notti inferiore alla richiesta'),
                    $tot.addClass('validation-error'), $tot.val('ERRORE');
                $document.guardian('error', true);
            } else {
                $in.removeClass('validation-error'), $tot.removeClass('validation-error'), $tot.val(r);
            }
            $tot.trigger("dateupdate");
        });

        function _parseDate(i) {
            false !== i, p = i.match(/(\d+)/g);
            return new Date(p[2], p[1] - 1, p[0]);
        }

        return this;
    };

    $.fn.moneyUpdate = function () {
        $document.guardian('error', false);
        var arr = {};
        $('[class*="js-money"]').each(function () {
            _evaluate($(this));
        });
        //anche per ttnights
        $('[class*="js-nights"]').each(function () {
            _evaluate($(this));
        });
        if ($('form').attr('id') == 'reservations') {
            _calculate();
            _update();
        }
        //Bugfix per quei record che, non si capisce come, non valorizza tutti i campi
        $document.on('change dateupdate', '[class*="js-money"]:not(#touristtax_amount)', function (e) {
            $document.guardian('error', false);
            e.preventDefault();
            _evaluate($(this));
            _calculate(true);
            _update();
        });

        $document.on('change', 'input[name=touristtax_nights_manual]', function (e) {
            e.preventDefault();
            var n = e.target.value;
            var t = n * TOURISTTAX_RATE;
            //update manual nights
            arr['tsnm'] = n;
            arr['tsm'] = t;
            _update();
        });

        function _hilightFieldAndNotify(moneyField, msgText) {
            $document.find("[data-money='" + moneyField + "']").addClass('needs-attention');
            $document.find("#js-flash-box").css('display', 'block');
            $document.find("#js-flash-box-msg").html(
                $.parseHTML($document.find("#js-flash-box-msg").text() + "<br />\n" + msgText));
            $document.find(".js-submit").attr('disabled', true);

            function _unlockForm() {
                $document.find("[data-money='" + moneyField + "']").removeClass('needs-attention');
                $document.find("#js-flash-box").css('display', 'none');
                $document.find(".js-submit").attr('disabled', false);
                $document.find("#js-flash-box-msg").text('');
                $document.find("[data-money='" + moneyField + "']").off('change',
                    "[data-money='" + moneyField + "']", _unlockForm);
            }

            //aggiunge event listener sul campo. appena viene toccato (Anche senza modifica) scatta il liberatutti
            $document.find("[data-money='" + moneyField + "']").keydown(_unlockForm)
        }

        function _evaluate(item) {
            arr[item.data('money')] = $document.numberFormat(item.val());
        }

        function _calculate(afteredit) {
            var afteredit = afteredit || false;
            if ((arr['nights'] > 0 && arr['pax'] > 0) !== false) {
                if ($('#ooc').prop('checked') === true) {
                    arr['f1'] = 0.00;
                    arr['f2'] = 0.00;
                    arr['g1'] = 0.00;
                    arr['j1'] = 0.00;
                    arr['b'] = 0.00;
                }
                // NEW RELEASE COUNT
                //every modification in this logic MUST be copied to SmoobuController
                //search for "UPDATE TOTALS" in SmoobuController
                if ($('#newcount').prop('checked') === true) {
                    if (arr['x'] > 0) {
                        arr['b'] = arr['x1'] * arr['u1'];
                        arr['b'] = arr['nights'] > 14 ? (arr['b'] * arr['u5']) : (arr['nights'] > 7 ? arr['b'] * arr['u4'] : arr['b']);
                        arr['b'] += arr['x2'] * arr['u2'];
                        if (arr['pax'] > 2) {
                            arr['b'] += arr['x3'] * arr['u3'] * (arr['pax'] - 2);
                        }
                        arr['a'] = arr['x'] - arr['b'];
                    }
                }
                arr['e'] = arr['a'] - arr['c'];
                arr['f'] = arr['f1'] + (arr['f2'] * (arr['pax'] - 2 > 0 ? arr['pax'] - 2 : 0));
                arr['g'] = arr['e'] * (arr['g1'] / 100);
                arr['h'] = (arr['e'] - arr['d'] - arr['f'] - arr['g']) * (arr['h1'] / 100);
                arr['i'] = arr['d'] + arr['f'] + arr['g'] + arr['h'];
                arr['k'] = $('#ooc').prop('checked') === true ? 0.00 : Math.round(arr['e'] - arr['i'] + arr['h']);
                arr['j'] = arr['j1'] * arr['nights'];
                arr['l'] = $('#ooc').prop('checked') === true ? 0.00 : arr['k'] - arr['j'];
                arr['m'] = $('#guestivaincluded').prop('checked') === true ? ((arr['a'] + arr['b'] - arr['k']) * ((arr['p1'] + 100) / 100)) : ($('#ooc').prop('checked') === true ? 0.00 : arr['a'] + arr['b'] - arr['k']);
                arr['n'] = $('#ooc').prop('checked') === true ? 0.00 : $document.numberRound(arr['m'] / ((arr['p1'] + 100) / 100));
                arr['p'] = $('#ooc').prop('checked') === true ? 0.00 : arr['m'] - arr['n'];
                arr['q'] = $('#ooc').prop('checked') === true ? arr['h'] + arr['c'] : arr['h'];
                arr['r'] = arr['q'];
                arr['s'] = $('#ooc').prop('checked') === true ? arr['a'] - (arr['r'] * ((100 + 22) / 100)) : arr['k'] - (arr['r'] * ((100 + 22) / 100));
                // just set to zero the first time
                if (arr['tsc'] === undefined) {
                    arr['tsc'] = 0;
                }
                if (arr['tsm'] === undefined) {
                    arr['tsm'] = 0;
                }
                if (arr['tsnm'] === undefined) {
                    arr['tsnm'] = 0;
                }
                if (arr['tsnc'] === undefined) {
                    arr['tsnc'] = 0;
                }
                //check manual flag
                var ttax_manual_override = $document.find('input[name=is_touristtax_manual_override]').is(':checked');
                //clean field, since _hilightFieldAndNotify appends to existing errors
                $document.find("#js-flash-box-msg").text('');
                $document.find("#js-flash-box").hide();
                var ttn = _calculateTouristTax();

                //automatically update values.. always
                var precTsc = arr['tsc'];
                var precTsnc = arr['tsnc'];
                arr['tsc'] = ttn.tax;
                arr['tsnc'] = ttn.nights;
                $document.find('#touristtax_nights_calculated').val(ttn.nights);
                $document.find('#touristtax_calculated').val(ttn.tax);

                if (ttax_manual_override) {
                    // var tsm = $document.find('#touristtax_manual').val();
                    var tsnm = $document.find('#touristtax_nights_manual').val();
                    arr['tsnm'] = tsnm;
                    // arr['tsm'] =
                    if (afteredit) {

                        if (arr['tsm'] === undefined || arr['tsm'] === null || arr['tsm'] === '') {
                            arr['tsm'] = arr['tsc'];
                        } else {
                            //evidenzio campo perché è da modificare
                            if (precTsc !== arr['tsc'])
                                _hilightFieldAndNotify('tsm', 'Devi toccare il campo TdS prima di poter salvare. ');
                        }

                        if (tsnm === undefined || tsnm === null || tsnm === '' || tsnm === 0 || tsnm === '0') {
                            $document.find('#touristtax_nights_manual').val(ttn.nights);
                            arr['tsnm'] = ttn.nights;
                        } else {
                            arr['tsnm'] = tsnm;//restore di quel che c'era prima
                            //evidenzio campo perché è da modificare
                            if (parseInt(precTsnc) !== ttn.nights)
                                _hilightFieldAndNotify('tsnm', 'Devi toccare il campo notti TdS prima di poter salvare. ');
                        }
                    }
                    arr['t'] = arr['tsm'];

                } else {

                    arr['t'] = arr['tsc'];
                }

                // arr['t'] = arr['tsm'] ? arr['tsm'] : arr['tsc']; //quale TdS copio nel totale della fattura guest?
                //update total
                arr['m'] += arr['t'];
            } else {
                _error();
            }
        }

        function _update(item) {
            $.each(arr, function (index, value) {
                value = (value !== 'ERRORE' && (value >= '0.00' || value >= '0')) ? ((index.length < 3 || index === 'tsc' || index === 'tsm') ? parseFloat(value).toFixed(2) : parseFloat(value)) : 'ERRORE';
                if (value === 'ERRORE') {
                    $document.find("[data-money='" + index + "']").val(value).addClass('validation-error');
                    // console.log("errore su " + index + ", value =" + arr[index]);
                    $document.guardian('error', true);
                } else {
                    $document.find("[data-money='" + index + "']").val(value).removeClass('validation-error');
                }
            });
        }

        function _error() {
            arr['i'] = arr['l'] = arr['j'] = arr['k'] = arr['n'] = arr['p'] = arr['m'] = arr['q'] = arr['r'] = 'ERRORE';
            $document.guardian('error', true);
        }

        function _calculateTouristTax(){
            //only if type == 2
            if ($('select[name=touristtax_type]').val() != TYPE_EASYGUEST) {
                return {tax: 0, nights: 0};
            }

            if(arr['nights'] <= 0 || arr['pax'] <= 0)
                return {tax: 0, nights: 0};

            var n = arr['nights'];
            //max is 7 nights
            if(n>7)
                n = 7;

            var p = arr['pax'];
            //need to count only guests > 12 years old
            if (housedMinor12 !== undefined && housedMinor12 > 0) {
                p = p - housedMinor12;
            }
            var tt = n * p * TOURISTTAX_RATE;
            //round in string
            return {tax: tt, nights: n * p, taxAsString: tt.toFixed(2)};
        }

        return this;
    };

    $.fn.apartmentDefault = function () {
        $document.on('change', '[name="ooc"]', function (e) {
            if ($('[name="ooc"]').prop('checked') === true) {
                val = parseFloat($document.numberFormat(0.00)).toFixed(2)
                $('[name="fixed_fee"]').val(val),
                    $('[name="fixed_fee_per_guest"]').val(val),
                    $('[name="fixed_rate_guest"]').val(val),
                    $('[name="fixed_utilities"]').val(val);
                $document.guardian('error', false);
            }
        });
        return this;
    };

    $.fn.switch = function () {
        if ($('[class*="js-switch"]')) {
            var elem = $('[class*="js-switch"]');
            _lock(elem);
            elem.on('click', function () {
                if (elem.is(":checkbox")) {
                    true === elem.prop('checked') ? _unlock(elem, false) : _lock(elem);
                } else {
                    _unlock(elem, true);
                }
            });
        }

        function _lock(elem) {
            $('[class*="js-unlock"]').each(function (e) {
                $item = $(this);
                if ($item.data('switch') == elem.data('switch')) {
                    $item.attr("readonly", true);
                    if ($item.is("select")) {
                        $item.prop('disabled', 'disabled');
                        $item.parent('div').addClass('js-unlock-container');
                    }
                }
            });
        }

        function _unlock(elem, reset) {
            $('[class*="js-unlock"]').each(function (e) {
                $item = $(this);
                $item.attr("readonly", false);
                if ($item.is("select")) {
                    $item.prop('disabled', false);
                    $item.parent('div').removeClass('js-unlock-container');
                }
            });
        }

        return this;
    };

    $.fn.taxCompiler = function () {
        $('[class*="js-tax"]').on('change', function (e) {
            var $this = $(this),
                rate = $this.data('taxrate'),
                value = 0.00;
            switch (parseInt($this.val())) {
                case 1: // R.A.
                    value = 20.00;
                    break;
                case 2: // IVA
                    value = 22.00;
                    break;
                case 3: // Ricevuta
                    value = 0.00;
                    break;
            }
            $('input[name="' + rate + '"]').val(parseFloat(value).toFixed(2));
        });
        $('#is_host_recharged').on('change', function (e) {
            $('#is_host_recharged').is(':checked') ? $('#host_recharge_amount').val($('#total').val()) : $('#host_recharge_amount').val('0.00');
        });
        return this;
    };

    $.fn.taxCalculator = function () {
        $('[class*="js-taxcalc"]').on('change', function (e) {
            var arr = {},
                $taxamount = $total = false;
            $('[class*="js-taxcalc"]').each(function () {
                $this = $(this);
                if ($taxamount === false && $this.data('taxcalc') == 'taxamount') {
                    $taxamount = $this;
                }
                if ($total === false && $this.data('taxcalc') == 'total') {
                    $total = $this;
                }
                if ($this.val()) {
                    arr[$this.data('taxcalc')] = $this.val();
                }
            });
            //fix.. if no touristtax, set it to zero for forms where ther is no such tax
            if(!('touristtax' in arr)){
                arr['touristtax'] = 0.0;
            }
            switch (parseInt(arr['taxtype'])) {


                case 1: // R.A.
                    $taxamount.val((arr['taxable'] * (arr['taxvalue'] / 100)).toFixed(2));
                    $total.val((arr['taxable'] - arr['touristtax'] - (arr['taxable'] * (arr['taxvalue'] / 100))).toFixed(2));
                    break;
                case 2: // IVA
                    $taxamount.val((arr['taxable'] * (arr['taxvalue'] / 100)).toFixed(2));
                    $total.val((+arr['taxable'] - arr['touristtax'] + +(arr['taxable'] * (arr['taxvalue'] / 100))).toFixed(2));
                    break;
                case 3: // Ricevuta
                    $taxamount.val((0).toFixed(2));
                    $total.val((arr['taxable'] - arr['touristtax']).toFixed(2));
                    break;
            }
        });
        return this;
    };

    $.fn.resetSection = function () {
        if ($('[class*="js-reset"]')) {
            var elem = $('[class*="js-reset"]');
            elem.on('click', function () {
                $('[class*="js-item-reset"]').each(function (e) {
                    $item = $(this);
                    if ($item.data('reset') == elem.data('reset')) {
                        $item.is(":checkbox") ? $item.prop('checked', false).change().attr("readonly", false).attr("disabled", false) : $item.val('').change().attr("readonly", false).attr("disabled", false);
                    }
                });
                if ($('#modify_' + elem.data('reset'))) {
                    $('#modify_' + elem.data('reset')).prop('checked', false).change();
                }
                $document.switch();
            });
        }
    };

    $.fn.lockForm = function () {
        var $form = ($(this).is('form')) ? $(this) : $(this).parents('form');
        $fields = $form.find(":input:not(input[type=submit]):not([name='_token']):not([name='_method']):not([name^='tasks__']):not([name='gi__paytype_id']):not([name='gi__paytype_description']):not([name='gi__is_payed']):not(button):not([name^='email__'])");
        $fields.each(function () {
            item = $(this).attr('name');
            if (
                item.indexOf("checkin__") === -1 &&
                item.indexOf("cleaning__") === -1
            ) {
                $(this).attr("readonly", true).attr("disabled", true);
                if ($(this).is("select")) {
                    $(this).prop('disabled', 'disabled');
                    $(this).parent('div').addClass('locked');
                }
            }
        });
        return this;
    };

    $.fn.formSubmit = function () {
        $('[class*="js-submit"]').on('click', function (e) {
            e.preventDefault();
            change = false, $button = $(this);
            if ($('[class*="js-alertchange"]')[0]) {
                field = $button.data('alertguard');
                $('[class*="js-alertchange"]').each(function (e) {
                    if ($(this).val() != $(this).data('original')) {
                        if (field) {
                            if ($('#' + field).val() != '' && $('#' + field).val() == $('#' + field).data('original')) change = true;
                        } else {
                            change = true;
                        }
                    }
                });
            }
            return (change ? ( UIkit.modal.confirm("Stai modificando la scheda principale del Guest, vuoi continuare?", function () {
                _submit($button);
            }) ) : _submit($button));
        });

        function _submit() {
            return (false === $guardian.error || !$guardian.error) ? $button.closest("form").submit() : UIkit.notify('Errore nel form, salvataggio disabilitato!!')
        }
    };

    $.fn.confirmDelete = function () {
        $('[class*="js-confirmdelete"]').on('click', function (e) {
            e.preventDefault();
            $this = $(this);
            UIkit.modal.confirm("Stai per cancellare questo record, vuoi continuare?", function () {
                document.location = $this.attr('href');
            });
        });
        return this;
    }

    $.fn.checkboxSelect = function () {
        if ($('[class*="js-checkbox"]')) {
            var elem = $('[class*="js-checkbox"]'),
                target = elem.data('checkbox');
            elem.addClass('button-locked');
            _update(elem, target);
            $('input[name*="' + target + '"]').on('change', function () {
                _update(elem, target);
            });
        }

        function _update(e, t) {
            if ($('input[name*="' + t + '"]').is(':checked')) {
                e.removeClass('button-locked');
            }
        }

        return this;
    };

    $.fn.yearSelect = function () {
        $document.on('change', '[name="year-select"]', function (e) {
            var path = window.location.pathname.split('/'), g = false;
            return (path.length == 4) ? path.pop() : '', window.location = 'http://' + window.location.host + path.join('/') + '/' + $(this).val();
        });
    };

    $.fn.providerSelect = function () {
        $document.on('change', '[name="provider_id"]', function (e) {
            // Provider: 2 is Booking, 3 is Direct, .
            // contract_paytype id /gi__paytipe_id is: 3 Bonifico  (tab Fatture + Contratti)
            if ($('[name="provider_id"]').val() != 3) {
                $("#contract_paytype_id").val(3).change();
                $("#gi__paytype_id").val(3).change();
            }
        });
        return this;
    };

    $.fn.invoiceViewer = function () {
        $('[class*="js-invoice-viewer"]').on('click', function (e) {
            $this = $(this);
            var token = $('meta[name="csrf-token"]').attr('content');
            $.ajax({
                beforeSend: function (xhr) {
                    return xhr.setRequestHeader('X-XSRF-Token', token);
                },
                url: $this.data('viewer'),
                type: 'GET'
            }).done(function (html) {
                $("#invoice-viewer").html(html);
                var modal = UIkit.modal($this.attr('href'));
                if (modal.isActive()) {
                    modal.hide();
                } else {
                    modal.show();
                }
            });
        });
    };

    $.fn.newCount = function () {
        $document.on('change', '[name="newcount"]', function (e) {
            var val = parseFloat($document.numberFormat(0.00)).toFixed(2);
            if ($('[name="newcount"]').prop('checked') === true) {
                $('[name="stay_amount"]').val(val).change().prop('readonly', true);
                $('[name="cleaning_amount"]').val(val).change().prop('readonly', true);
                $('[name="guest_payment_amount"]').val(val).change().prop('readonly', false);
            } else {
                $('[name="stay_amount"]').val(val).change().prop('readonly', false);
                $('[name="cleaning_amount"]').val(val).change().prop('readonly', false);
                $('[name="guest_payment_amount"]').val(val).change().prop('readonly', true);
            }
            $document.guardian('error', false);
        });
    };

    $.fn.expenseViewer = function () {
        $('[class*="js-expense-viewer"]').on('click', function (e) {
            $this = $(this);
            var token = $('meta[name="csrf-token"]').attr('content');
            $.ajax({
                beforeSend: function (xhr) {
                    return xhr.setRequestHeader('X-XSRF-Token', token);
                },
                url: $this.data('viewer'),
                type: 'GET'
            }).done(function (html) {
                $("#expense-viewer").html(html);
                var modal = UIkit.modal($this.attr('href'));
                if (modal.isActive()) {
                    modal.hide();
                } else {
                    modal.show();
                }
            });
        });
    }

    $.fn.supplierViewer = function () {
        $('[class*="js-supplier-viewer"]').on('click', function (e) {
            $this = $(this);
            var token = $('meta[name="csrf-token"]').attr('content');
            $.ajax({
                beforeSend: function (xhr) {
                    return xhr.setRequestHeader('X-XSRF-Token', token);
                },
                url: $this.data('viewer'),
                type: 'GET'
            }).done(function (html) {
                $("#supplier-viewer").html(html);
                var modal = UIkit.modal($this.attr('href'));
                if (modal.isActive()) {
                    modal.hide();
                } else {
                    modal.show();
                }
            });
        });
    }

    $.fn.importHoused = function () {
        $('[class*="js-housed"]').on('click', function (e) {
            e.preventDefault();
            $this = $(this);
            UIkit.modal.confirm("Stai per importare i dati degli alloggiati eliminando quelli pre-esistenti nella scheda, vuoi continuare?", function () {
                var token = $('meta[name="csrf-token"]').attr('content');
                $.ajax({
                    beforeSend: function (xhr) {
                        return xhr.setRequestHeader('X-XSRF-Token', token);
                    },
                    url: $this.data('id') + '/housed',
                    type: 'GET'
                }).done(function (html) {
                    $('[class*="js-item-reset"]').each(function (e) {
                        $item = $(this);
                        if ($item.data('reset') == $this.data('reset')) {
                            $item.is(":checkbox") ? $item.prop('checked', false).change().attr("readonly", false).attr("disabled", false) : $item.val('').change().attr("readonly", false).attr("disabled", false);
                        }
                    });
                    if ($('#modify_' + $this.data('reset'))) {
                        $('#modify_' + $this.data('reset')).prop('checked', false).change();
                    }

                    //if company, don't fill in Nome, Cognome, data di nascita and codice fiscale
                    var addCompany = false;
                    if(html.invoice.companyname){
                        if(html.invoice.country_code == 'IT'){
                            if( html.invoice.vat_number &&
                            (html.invoice.email_pec || html.invoice.einvoice_destination_code)) {
                                addCompany = true;
                            }else{
                                console.log("Dati incompleti per fattura elettronica Italiana (sdi/PEC)");
                                UIkit.modal.alert("Dati incompleti per fattura elettronica Italiana (sdi/PEC), usiamo dati del guest");
                            }
                        }else{
                            if(html.invoice.vat_number){
                                addCompany = true;
                            }else{
                                console.log("Dati incompleti per fattura elettronica, manca PIVA");
                                UIkit.modal.alert("Dati incompleti per fattura elettronica, manca PIVA, usiamo dati del guest");
                            }

                        }
                    }
                    // if(html.invoice.companyname && html.invoice.vat_number &&
                    //     (html.invoice.email_pec || html.invoice.einvoice_destination_code)){
                    if(addCompany){
                        $("#guest_companyname").val(html.invoice.companyname);
                        $("#guest_vat_number").val(html.invoice.vat_number);
                        $("#guest_email_pec").val(html.invoice.email_pec);
                        $("#guest_einvoice_destination_code").val(html.invoice.einvoice_destination_code);
                    }else{
                        $("#guest_firstname").val(html.invoice.firstname);
                        $("#guest_lastname").val(html.invoice.lastname);
                        $("#guest_birth_date").val(html.housed.birthdate);
                        $("#guest_tax_code").val(html.invoice.tax_code);

                    }
                    $("#guest_address").val(html.invoice.address);
                    $("#guest_postcode").val(html.invoice.postcode);
                    $("#guest_city").val(html.invoice.city);
                    $("#guest_province").val(html.invoice.province);
                    $("#guest_country_code").val(html.invoice.country_code).change();
                    $("#guest_email").val(html.invoice.email);
                    $("#guest_document_id").val(html.housed.document).change();
                    $("#guest_document_number").val(html.housed.document_number);
                });
            });
        });
    }

    $.fn.menuLink = function () {
        $('a[class*="js-menulink"]').on('click', function (e) {
            e.preventDefault();
            window.location.href = $(this).data('link');
        });
    };

})(jQuery, window, document);
